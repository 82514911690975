.right_main {
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    padding: 0 15px;
}

.heading {
    font-weight: 600;
    font-size: 30px;
    margin: 14px 0;
}

.heading_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main_container {
    background-color: #1E2022;
    border-radius: 12px;
    display: block;
    padding-bottom: 12px;
}

.addAccount_btn {
    height: 42px;
    color: #FFFFFF;
    line-height: 22px;
    font-weight: 600;
}

.account_header {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 12px 6px 12px;
    line-height: 1.5rem;
    justify-content: space-between;
}

.account_search_div {
    position: relative;
    width: 20%;
}

.account_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.account_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 270px;
}

.account_main_div {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5rem;
    max-height: calc(100vh - 230px);
    min-height: calc(100vh - 230px);
    overflow-y: scroll;
}

.account_main_div::-webkit-scrollbar {
    display: none;
}

.accountCard_div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 8px;
}

.accountCard {
    min-width: 276px;
    max-width: 276px;
    height: 300px;
    background: #18191B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    margin: 10px;
    z-index: 1;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.accountCard h6 {
    font-size: 20px;
}
.accountCard p {
    margin-bottom: 5px;
}
.statusActive {
    color: #07f707;
}

.statusDisable {
    color: red;
}


.acmodal .modal-content {
    background: #000 !important;
    color: #fff;
    border: 1px solid;
}

.typeCheck input {
    border: 1px solid #fff;
}

.ThreeDots {
    position: absolute;
    padding: 14px 6px 6px 6px;
    display: flex;
    right: 0;
    z-index: 999;
    float: right;
    cursor: pointer;
}

.accountCard_img {
    margin-top: 24px;
    height: 100%;
    max-height: 130px;
    margin-bottom: 10px;
}

.inputField {
    display: flex;
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    height: 48px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    padding: 0 10px;
    text-align: center;
    font-size: 20px;
    margin-top: 16px;
}

.warningModal_subHeading {
    text-align: center;
    font-size: 20px;
    margin: 16px 0;
}

.warningBtn_div {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 70%;
    text-align: center;
}

.dragAndDrop_div {
    height: 224px;
    position: absolute;
    width: 494px;
    background-color: transparent;
    z-index: 1;
    margin: 32px 0 16px 0;
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 28px 0 0px 0;
    margin: 12px 0;
}

.packing_image {
    background: transparent;
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}

.border_OR_div {
    border: 1px solid #FFFFFF;
    width: 80%;
    margin: 0;
}

.OR_css {
    position: absolute;
    margin-top: 16px;
    background: #1E2022;
    padding: 0 20px;
    font-size: 20px;
}

.browseFiles_btn {
    border: 1px solid #FFFFFF;
    margin: 20px auto;
    border-radius: 6px;
    background: transparent;
    /* border: none; */
    outline: none;
    line-height: 44px;
    width: 200px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}

.reciptImage_div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 12px;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
}

.reciptImage_delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    border: 3px solid #00000038;
    border-radius: 6px;
    padding: 4px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
}

.text_center {
    text-align: center;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 16px auto 24px;
}

.input_securitypin {
    display: flex;
    width: 75%;
    margin: auto;
    box-sizing: border-box;
    height: 48px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    padding: 0 10px;
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 10px;
}

.securityPin_heading {
    font-size: 20px;
}

.securityPin_header {
    font-size: 24px;
    font-weight: 400;
    /* color: red; */
}

.modal_backdrop {
    width: 100%;
    position: absolute;
    /* top: 0; */
    /* bottom: 0; */
    /* right: 0; */
    display: flex;
    justify-content: right;
    align-items: center;
    /* margin: auto; */
    z-index: 1090;
    /* min-height: 100vh; */
    /* float: inherit; */
    margin-top: 36px;
}

.modal_content {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 70px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 16px;
    /* z-index: 999; */
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_content span {
    line-height: 24px;
    font-weight: 600;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content span:hover {
    text-decoration: underline;
}

.warningPin_div {
    /* display: flex;
    /* justify-content: space-between; */
    margin: 20px auto;
    width: 100%;
    text-align: center;
}

.alert_label {
    font-size: 13px;
    text-align: left;
    width: 75%;
    color: red;
    font-weight: 600;
    margin-top: 8px;
}