.modal {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1;
    /* Overlay effect: positioned over other containers */
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    position: fixed;
    /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto;
    /* Enable scroll if needed */
    padding-top: 80px;
    /* Location of the content container */
  }
  
  /* Modal content */
  .modal_content {
    background-color: white;
    /* width: 70%;  */
    max-width: 640px;
    /* Max width where it stops expanding */
    /* height: 70%; */
    margin: auto;
    /* Auto margin according to the element width */
    padding: 10px;
    border: 1px solid black;
    border-radius: 20px;
    /* Optional. Rounds container corners */
  }
  .text_center {
    margin-top: 30px;
    text-align: center;
  }
  .imputdiv {
margin-top: 20px;
  }
  
  .close {
    color: #aaaaaa;
    float: right;
    /* Positioned to the right of the parent container whichever size it is */
    font-size: 25px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .imgcrpBtn {
display: flex;
justify-content: center;
margin: 10px;
  }