.reciptModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100%;
}

.reciptModal_content {
    width: 599px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #1E2022;
    color: #FFFFFF;
    border-radius: 12px;
    padding: 0 12px;
}

.reciptModal_content::-webkit-scrollbar {
    display: none;
}

.text_center {
    text-align: center;
}

.reciptHeader_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.reciptHeader_detail {}

.recipt_tableDiv {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.recipt_table_cell {
    min-width: 50%;
    text-align: center;
    border: 1px solid #FFFFFF;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
}

.reciptHeading {
    font-weight: 600;
    /* padding-bottom: 40px; */
}

.reciptContentDiv {
    padding: 0 40px;
}

.recipt_selectDiv {
    display: flex;
}

.recipt_selectDiv_Account {
    display: flex;
    margin-top: 32px;
}

.reciptMethod_select {
    box-sizing: border-box;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    padding: 0 6px;
    text-align: left;
    color: #FFFFFF;
}

.reciptMethod_select option {
    background-color: #1E2022;
    color: #FFFFFF !important;
}

.method_txt {
    /* margin-right: 40px; */
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.reciptAmount_txt {
    margin: 18px 0;
    font-size: 18px;
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
    width: 100%;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
    padding-left: 84px;
}

.reciptImage_delete {
    right: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    /* border: 3px solid #00000038; */
    border-radius: 6px;
    padding: 10px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.reciptImage_div {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin-top: 12px;
    position: relative;   
}

.recipt_inputField {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin: 32px 0 12px 0;
}

.input_accountNumber {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin-bottom: 12px;
}

.reciept_paymentlink {
    width: 100%;
    background-color: transparent;
    height: 64px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #FFFFFF;
    /* letter-spacing: 4px; */
    margin: 8px 0;
    padding: 0 6px;
    margin-right: 12px;
}
.recipt_btn a {
    text-decoration: none;
}

.recipt_inputField_ref {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin-top: 32px;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 16px auto 24px;
}

.recipt_payNow {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    line-height: 28px;
    margin-top: 16px;
}

.reciptPositionAbsolute {
    z-index: 999;
    /* position: absolute; */
}

.recipt_AmountDiv {
    margin: 20px 0;
}

.reciptAmount_input {
    background-color: transparent;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    outline: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    float: right;
    min-width: 400px;
}

.amount_txt {
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.dragAndDrop_div {
    height: 224px;
    position: absolute;
    width: 494px;
    background-color: transparent;
    z-index: 1;
    margin: 32px 0 16px 0;
}

.alert_dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px 0 0px 0;
    margin-top: 32px;
    border: 3px solid red;
}
.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 28px 0 0px 0;
    /* margin: 12px 0; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}
.alert_drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
    color: red;
}

.border_OR_div {
    border: 1px solid #FFFFFF;
    width: 80%;
    margin: 0;
}

.OR_css {
    position: absolute;
    margin-top: 16px;
    background: #1E2022;
    padding: 0 20px;
    font-size: 20px;
}
.alert_OR_css {
    position: absolute;
    margin-top: 16px;
    background: #1E2022;
    padding: 0 20px;
    font-size: 20px;
    color: red
}

.browseFiles_btn {
    border: 1px solid #FFFFFF;
    margin: 20px auto;
    border-radius: 6px;
    background: transparent;
    /* border: none; */
    outline: none;
    line-height: 44px;
    width: 200px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}
.alert_browseFiles_btn {
    border: 1px solid red;
    margin: 20px auto;
    border-radius: 6px;
    background: transparent;
    /* border: none; */
    outline: none;
    line-height: 44px;
    width: 200px;
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.packing_image {
    background: transparent;
}

.methodAndAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.method_selectDiv {
    width: 50%;
    margin-right: 5px;
}
.amount_selectDiv {
    width: 50%;
    margin-left: 5px;
}

.methodSelect {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #FFFFFF;
}

.amount_input {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size:18px;
    color: #FFFFFF;
}

.methodSelect option {
    background-color: #1E2022;
}

.submethod_txt {
    font-size: 20px;
}
.method {
    font-size: 20px;
}

.submethod_div {
    display: flex;
    margin-top: 32px;
}