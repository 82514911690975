.main_div {
    width: 100%;
    margin: 12px 16px;
}

.heading {
    font-size: 36px;
    color: #fff;
    margin: 0 0 12px 0;
}

.addwarehouse_btn {
    border: 1.5px solid #1ffc33;
    color: #e3e3e3;
    border-radius: 7px;
    line-height: 36px;
    height: 40px;
    padding: 0 10px;
    font-weight: 600;
    cursor: pointer;
}

.Warehouse_card {
    background: #1e2022;
    color: #fff;
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 12px;
}

.ViewEdit_div {
    width: 64px;
    height: 64px;
    background-color: #000;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-right: 20px;
}

.ViewEdit_div svg {
    margin: 0 auto;
    width: 26px;
    height: 26px;
}

.ViewEdit_div span {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
}

.warehouse_name {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 300;
}

.modal_div {
    width: 100%;
    display: flex;
    color: #fff;
}

.modal_div input {
    width: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    height: 36px;
    border-radius: 4px;
    margin-bottom: 4px;
    color: #fff;
    padding: 0 5px;
    line-height: 36px;
    font-size: 15px;
}

.modal_div textarea {
    width: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    /* height: 36px; */
    border-radius: 4px;
    color: #fff;
}

.modal_div span {
   line-height: 28px;
}

.days_radio {
    width: 16px !important;
    height: 16px !important;
    margin: 0 10px 0 0px;
    margin-bottom: 0px !important;
}

.status_radio {
    display: flex;
    width: 64%;
}

.workDays {
    width: 100%;
    /* background-color: transparent; */
    /* border: 1px solid #fff; */
    /* height: 36px; */
    /* border-radius: 4px; */
    color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 18px;
}

.delete_btn {
    font-size: 10px;
}