.modal_backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1090;
    height: 100%;
}

.modal_content {
    width: 599px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #fff;
    color: #000;
    padding: 25px;
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.packingImage_content {
    width: 90%;
    overflow-y: scroll;
    min-height: 150px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 10px 8px 24px 8px;
    margin-left: 66px;
}

.packingImage_content::-webkit-scrollbar {
    display: none;
}


.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal_header h3 {
    margin: 0;
}

.modal_close_button {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;
}

.main_search_div {
    position: relative;
}

.inputSearch {
    width: 100%;
    padding: 10px 10px 10px 30px;
    outline: none;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    /* position: relative;  */
}

.search_icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 12px;
    width: 15px;
    height: 15px;
}

.main_search_div h3 {
    text-align: center;
    margin-top: 0;
}

.main_search_div p {
    text-align: center;
    color: darkgray;
}

.locations {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}

.locations h4 {
    margin: 0;
}

/* @media only screen and (max-width: 991px) {
    .modal_content {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .modal_content {
        width: 90%;
    }
}
*/

.recipt_tableDiv {
    width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.recipt_table_cell {
    min-width: 50%;
    text-align: center;
    border: 1px solid #000000;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
}

.reciptContentDiv {
    padding: 0;
}

.method {
    font-size: 15px;
    font-weight: 600;
}

.methodSelect {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #000;
}

.methodSelect option {
    background-color: #ffffff;
}

.amount_input {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size:18px;
    color: #000000;
}

.submethod_txt {
    font-size: 15px;
    font-weight: 600;
}

.reciptMethod_select {
    box-sizing: border-box;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    text-align: left;
    color: #000000;
    background: #F3F6F8;
    width: 100%;
    margin: 0px 0 10px 1px;
}
.subMethod_select {
    box-sizing: border-box;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    /* padding: 0 6px; */
    text-align: left;
    color: #000000;
    background: #F3F6F8;
    width: 100%;
    margin: 0;
}

.reciptMethod_select option {
    background-color: #FFFFFF;
    color: #000000 !important;
}

.reciptImage_div {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin-top: 12px;
    position: relative;   
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reciptImage_delete {
    right: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    /* border: 3px solid #00000038; */
    border-radius: 6px;
    padding: 10px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
    padding-left: 84px;
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px 0 0px 0;
    /* margin: 12px 0; */
}

.recipt_inputField_ref {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    color: #000;
    letter-spacing: 3px;
    margin: 16px 3px 12px 3px;
    outline: none;
    font-weight: 600;
}

.recipt_inputField {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 3px;
    margin: 16px 3px 12px 3px;
    color: #000;
    outline: none;
    font-weight: 600;
}

.recipt_transactionAmount {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 3px;
    margin: 6px 1px 12px 1px;
    color: #000;
    outline: none;
    font-weight: 600;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 4px auto 6px;
}

.orders_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
}

.order_btn {
    border: 1px solid #000000;
    outline: none;
    background: #fff;
    border-radius: 3px;
    width: 50%;
    height: 36px;
    margin: 0 10px;
}

.order_btn1 {
    border: 1px solid #000000;
    outline: none;
    background: #000;
    border-radius: 3px;
    color: #ffff;
    width: 50%;
    height: 36px;
    margin: 0 10px;
}

.reciptHeading {
    font-size: 20px;
} 

.subMethod_div {
    /* margin-right: 0.25rem; */
    width: 100%;
    min-width: 49%;
    margin: 0 6px 12px 0;
}

@media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 10px;
        position: absolute;
        bottom: 0;
    }
}