.main_Container {
    color: #FFFFFF;
    padding: 0 12px;
    width: 100%;
}

.inventory_scroll_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.inventory_scroll_div::-webkit-scrollbar {
    display: none;
}

.outside_scroll_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
    min-height: calc(100vh - 222px);
}

.outside_scroll_div::-webkit-scrollbar {
    display: none;
}
.outside_scroll_coll {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
    min-height: calc(100vh - 230px);
}

.outside_scroll_coll::-webkit-scrollbar {
    display: none;
}

.inventory_heading {
    margin: 14px 0;
}

.InventoryCard {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px;
    margin: 8px 0;
    cursor: pointer;
}

.produts_details_div {
    width: 40%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collections_details_div {
    width: 30%;
    max-width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activeRadioBtn {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 4px;
}

.status_active {
    padding: 2px 0 0 2px;
    color: #ffffff61;
}

.productName_div {
    min-width: 240px;
}

.productName {
    margin: 0;
    font-weight: 300;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF;
    padding-left: 5px;
}

.view_div {
    width: 90px;
    height: 90px;
    background: #cccccc08;
    box-shadow: rgba(0, 0, 0, 6%) 0px 5px 15px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.qty {
    color: #00aff0;
    font-size: 28px;
}

input[type="radio"] {
    height: 16px;
    width: 16px;
    vertical-align: middle;
}

.goBack {
    cursor: pointer;
    border-bottom: 1px solid #FFFFFF;
    line-height: 32px;
}

/* ----------------------------------------collection------------------------------------------------ */

.collectionInfoCard {
    margin-top: 10px;
    width: 100%;
    min-height: 140px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding-bottom: 12px;
    position: relative;
}

.collection_heading {
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: #FFFFFF;
    margin: 0;
    padding-top: 10px;
}

.collectionInfo_div {
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
    justify-content: center;
    align-items: center;
    min-height: 120px;
}

.details_div {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 104px;
    margin: 12px 0;
}

.details_txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #808080d6;
}

.inputField {
    box-sizing: border-box;
    background: transparent;
    color: #FFFFFF;
    outline: none;
    border: 0.5px solid #F3F3F3;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-top: 6px;
    text-align: center;
}

.selectDropdown {
    margin-top: 6px;
    box-sizing: border-box;
    background: transparent;
    color: #FFFFFF;
    outline: none;
    border: 0.5px solid #F3F3F3;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
}

.ItemsCollection_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seacrh_div {
    width: 25%;
    position: relative;
}

.seacrh_inputField {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    height: 50px;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    padding: 0 4px;
    font-size: 15px;
    outline: none;
    border-radius: 40px;
    padding: 0 16px;
}

.search_Icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding-right: 4%;
    padding-top: 5px;
    cursor: pointer;
}

.editAll {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 10px;
    color: #ffffff69;
    margin: 10px 0;
    cursor: pointer;
}

.collectionCard {
    box-sizing: border-box;
    width: 100%;
    height: 104px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px;
    margin: 8px 0;
    /* cursor: pointer; */
    margin-bottom: 20px;
}

.totalItem_div {
    min-width: 100px;
    font-size: 46px;
    font-weight: 100;
    text-align: center;
}

.weight_div {
    width: 16%;
    display: flex;
    justify-content: center;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
}

.totalItem_details_div {
    min-width: 114px;
}

.outsourcedStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}

.collectionCard_div {
    box-sizing: border-box;
    width: 100%;
    height: 140px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px;
    margin: 8px 0;
    cursor: pointer;
}

.outsourcedProduct {
    font-size: 13px;
    color: #a9a9a9a3;
}

.itemName {
    margin: 0;
    font-weight: 300;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF;
    padding-left: 5px;
    padding-bottom: 12px;
}

.updateCollectionBtn {
    color: #FFFFFF;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    margin: 8px 8px 0 0;
}

.message {
    text-align: center;
    margin: 30px 0;
    font-size: 24px;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    padding-bottom: 30px;
}

.selectDropdown option {
    background-color: #1E2022;
    text-align: left;
}

.dispatchOrders_height {
    overflow-y: scroll;
    max-height: 756px;
    min-height: 756px;
}

.dispatchOrders_height::-webkit-scrollbar {
    display: none;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
    /* width: 20%; */
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}

.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.modalMain_heading {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0px;
}


.orderInput_div {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 6px;
    position: relative;
}

.inputFields_div {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin: 0 20px 10px;

}

.inputFields_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}


.inputField_name {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 36px;
    font-size: 15px;
    font-weight: 100 !important;
    min-height: 40px;
    padding: 0 8px;
    outline: none;
    width: 100%;
}

.inputField_name:focus {
    border: 1px solid #1FFC33;
}

.inputField_name option {
    background-color: #1E2022;
    text-align: left;
}

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.uploadImage_btn {
    border: 1px solid #1FFC33;
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.demoImage_div {
    width: 86px;
    height: 48px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}


.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    border: 3px solid #00000045;
    border-radius: 8px;
}

.modalButton_div {
    width: 372px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 28px;
}

.OutSide_heading {
    font-size: 32px;
    line-height: 56px;

}

.OutSide_scroll_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 140px);
}