.pagination_container {
    display: flex;
    float: right;
    margin-top: 30px;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .page_item {
    background-color: #fff;
    width: 25px;
    height: 26px;
    color: #000000;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    margin: 0 4px;
  }
  
  .active {
    background-color: #337ab7;
    text-decoration: none;
    width: 25px;
    height: 27px;
    text-align: center;
    line-height: 24px;
    color: #FFFFFF;
  }
  
  .pagination_btn {
    border: 1px solid #0066cc;
    background-color: #0099cc;
    color: #ffffff;
    padding: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 4px;
    width: 100px;
  }
  .pagination_btn:hover {
    border: 1px solid #0099cc;
    background-color: #00aacc;
    color: #ffffff;
    padding: 0px 10px;
  }
  .paginationDisable_btn{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    padding: 0px 10px;
    border-radius: 4px;
    margin: 0 4px;
    width: 100px;
  }
  
  .disableDots {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #000000;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    margin: 0 4px;
    font-weight: bold;
    text-align: center;
    cursor: context-menu;
  }

  .pagination_Main {
    display: flex;
    justify-content: space-between;
  }

  .goToPage_div {
    margin-top: 30px;
    padding-left: 10px;
  }

  .page_btn {
    border: 1px solid #0066cc;
    background-color: #0099cc;
    color: #ffffff;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 4px;
    width: 40px;
  }

  .page_input {
    outline: none;
    font-size: 16px;
    max-width: 60px;
    text-align: center;
    font-weight: 600;
  }