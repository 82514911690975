.right_main {
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  padding: 0 15px;
}

.addDealers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0;

}

.dealers {
  font-weight: 600;
  font-size: 30px;
  margin: 0;
}

.Dealer_main_container {
  background-color: #1E2022;
  border-radius: 12px;
  display: block;
  padding-bottom: 12px;
}

.dealer_header {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 12px 6px 12px;
  line-height: 1.5rem;
  justify-content: space-between;
}

.dealer_header_right {
  display: flex;
  flex-direction: row;
}

.dealer_search {
  border-radius: 50px;
  height: 49px;
  padding-left: 30px;
  padding-right: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: #ffffff;
  outline-offset: 0px !important;
  outline: none;
  width: 100%;
  min-width: 270px;
}

.dealer_search_div {
  position: relative;
  width: 20%;
}

.dealer_search_icon {
  position: absolute;
  left: 8px;
  top: 15px;
}

.dealer_Sort_By {
  padding-right: 30px;
}

.dealer_sortBy_txt {
  font-size: 20px;
  padding-right: 12px;
}

.dealer_sortBy_select {
  border: 1px solid #ffffff;
  height: 49px;
  width: 234px;
  background-color: transparent;
  color: #ffffff;
  outline: none;
  padding-left: 12px;
}

.dealer_sortBy_color {
  color: #000000;
  background-color: #ffffff !important;
}

.dealer_header_select_days {
  max-height: 49px;
  font-size: 13px;
  color: black;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  align-items: center;
  width: 420px;
  justify-content: center;
}

.dealer_days_select {
  width: 102px;
  text-align: center;
  border-radius: 25px;
  padding: 8px 0px;
  cursor: pointer;
}

.days_active {
  background-color: #E3E7EF;
  cursor: pointer;
  font-weight: bold;
}

.dealer_main_div {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5rem;
  max-height: calc(100vh - 280px);
  min-height: calc(100vh - 280px);
  overflow-y: scroll;
}

.dealer_main_div::-webkit-scrollbar {
  display: none;
}

.dealer_card {
  position: relative;
  width: 16.66666%;
}

.dealer_card_div {
  margin: 10px;
  padding: 10px;
  background-color: #000000;
  border-radius: 24px;
  text-align: center;
  min-height: 230px;
}

.dealer_image {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  display: block;
  margin: auto;
  object-fit: cover;
}

.dealer_name {
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0px;
}

.dealeStore_owner {

  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  color: gray;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 1.5em !important;
}

.dealer_btn_div {
  display: flex;
  justify-content: space-between;
  width: 64%;
  margin: auto;
  margin-top: 10px;
}

.dealer_Btn {
  background-color: #1E2022;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.dealer_status {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding-right: 4px;
  cursor: pointer;
}

.dealer_status_icon {
  padding-right: 12px;
}

.storeName_hide {
  visibility: hidden;
  min-height: 40px;
}

.name_char {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  display: flex;
  margin: auto;
  object-fit: cover;
  border: 3px solid #FFFFFF;
  justify-content: center;
  align-items: center;
  font-size: 62px;
}

.name_char_mb {
  padding-bottom: 8px;
}

.newIcon_div {
  padding-right: 12px;
}

.newIcon {
  background-color: #009effed;
  color: #FFFFFF;
  width: 32px;
  height: 31px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  line-height: 29px;
}

.cursor_pointer {
  cursor: pointer;
}

.pagination_div {
  bottom: 0;
}

.addDealerButton {
  color: #FFFFFF;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:1680px) {
  .dealer_card {
    width: 20%;
  }
}

@media (max-width:1400px) {
  .dealer_card {
    width: 25%;
  }
}

@media (max-width:1200px) {
  .dealer_header_select_days {
    width: 296px;
  }

  .dealer_days_select {
    width: 72px;
  }

  .dealer_sortBy_select {
    width: 160px;
  }

  .dealer_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
  }

  .dealer_days_select {
    font-size: 12px;
  }
}

@media (max-width:1100px) {
  .dealer_card {
    width: 33.333%;
  }
}

.modalMain_heading {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0px;
}

.nameAndDesignation {
  display: flex;
  flex-direction: row;
  width: 85%;
  margin: auto;
  margin-top: 20px;
}

.inputFieldName {
  width: 100%;
}

.inputFields_heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
}

.inputField_name {
  box-sizing: border-box;
  background-color: transparent;
  border: 0.5px solid #F3F3F3;
  border-radius: 3px;
  color: #FFFFFF;
  line-height: 36px;
  font-size: 15px;
  font-weight: 100 !important;
  min-height: 40px;
  padding: 0 8px;
  outline: none;
  width: 100%;
}

.DesignationFields_div {
  margin-left: 10px;
  width: 100%;
}

.inputField_designation {
  box-sizing: border-box;
  background-color: transparent;
  border: 0.5px solid #F3F3F3;
  border-radius: 3px;
  color: #FFFFFF;
  line-height: 36px;
  font-size: 15px;
  font-weight: 100 !important;
  min-height: 40px;
  padding: 0 8px;
  outline: none;
  width: 100%;
}

.inputFields_div {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
}

.modalButton_div {
  width: 372px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
}
.message {
  text-align: center;
  margin: 30px 0;
  font-size: 24px;
}

.massageModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  padding-bottom: 30px;
}

.managerName {
  margin: 14px 0;
}