.header {
    background-color: #1E2022;
    height: 66px;
    max-width: 1920px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    align-items: center;
}

.wallIcon_image {
    width: 66px;
    max-width: 66px;
    max-height: 50px;
    margin-left: 8px;
}

.headerItems_div {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.header_item {
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    text-align: center;
}

.logout {
    position: absolute;
    background: dimgray;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 999;
}

.toggleBtn {
    background-color: #ffffff;
    width: 60px;
    border-radius: 25px;
    height: 25px;
    color: #000000;
}

.icon {
    max-width: 24px;
    margin-top: 4px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(45px);
    -ms-transform: translateX(45px);
    transform: translateX(45px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.dealer_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 214px;
    justify-content: center;
    padding: 2px 8px;
    text-transform: capitalize;
}

.dealer_days_select {
    /* width: 102px; */
    text-align: center;
    border-radius: 25px;
    padding: 10px;
    cursor: pointer;
    color: gray;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
}


/* -------------------packing-header---------------------- */


.header_main_div {
    top: 0;
    width: 100%;
    z-index: 999;
    position: 'sticky';
    /* background-color: #ffffff; */
}

.header_div {
    height: 50px;
    margin: auto;
    display: flex;
    padding: 0 10px;
    max-width: 1084px;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-between;
    border-bottom: 1px solid darkgray;
}

.wallicon_image {
    width: 50px;
}

.header_right_div {
    display: flex;
    min-width: 148px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.header_item_txt {
    font-size: 10px;
    font-weight: 500;
    color: #3A3A3A;
    line-height: 13px;
    text-align: center;
    font-style: normal;
    text-transform: capitalize;
}

.icon_name {
    font-size: 8px;
    color: #7A7A7A;
    font-weight: 500;
    line-height: 10px;
    font-style: normal;
}

.menu_items_main {
    list-style-type: none;
    padding-left: 0;
}

.menu_items_main div {
    display: flex;
    align-items: center;
    border-bottom: .5px solid #c7c7c7!important;
    padding: 10px 0 20px 15px;
    margin-bottom: 10px;
}

.menu_items_main a {
    text-decoration: none;
    color: inherit;
}

.menu_items_main svg {
    fill: #000;
    margin-right: 20px;
    width: 20px;
}

.menu_item {
    height: 36px;
    width: 100%;
}

.menu_item a {
    width: 100%;
}

.multipleManager {
    border: 1px solid #000;
    border-radius: 15px;
    height: 30px;
    width: 60px;
    color: #000;
    font-size: 13px;
}